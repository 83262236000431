import './App.css';
import config from "./coreFIles/config";
import Login from './component/login';
import Register from './component/register';
import Home from './component/home';
import Addetails from './component/addetails';
import Adslist from './component/adslist';
import AllAdslist from './component/alladslist';
import AddAd from './component/addad';
import EditAd from './component/editad';
import ManageAd from './component/managead';
import About from './component/about';
import Advertise from './component/advertise';
import Help from './component/help';
import Terms from './component/terms';
import Privacy from './component/privacy';
import Profile from './component/profile';
import Contact from './component/contact';

import { BrowserRouter, Route, Routes } from 'react-router-dom'

	function App() {
		return (
		<BrowserRouter>
			<div>
				<Routes>
					<Route path={`${config.baseUrl}login`} element={<Login />} />
					<Route path={`${config.baseUrl}register`} element={<Register />} />
					<Route path={`${config.baseUrl}`} element={<Home />} />
					<Route path={`${config.baseUrl}ad/:id/:any`} element={<Addetails />} />
					<Route path={`${config.baseUrl}cat/:id/:any`} element={<Adslist />} />
					<Route path={`${config.baseUrl}cat/:id/action/all/:any`} element={<AllAdslist />} />
					<Route path={`${config.baseUrl}addad`} element={<AddAd />} />
					<Route path={`${config.baseUrl}edit-ad/:id`} element={<EditAd />} />
					<Route path={`${config.baseUrl}manage-ads`} element={<ManageAd />} />
					<Route path={`${config.baseUrl}about`} element={<About />} />
					<Route path={`${config.baseUrl}advertise-with-us`} element={<Advertise />} />
					<Route path={`${config.baseUrl}help`} element={<Help />} />
					<Route path={`${config.baseUrl}terms-and-conditions`} element={<Terms />} />
					<Route path={`${config.baseUrl}privacy-policy`} element={<Privacy />} />
					<Route path={`${config.baseUrl}profile-update`} element={<Profile />} />
					<Route path={`${config.baseUrl}contact-us`} element={<Contact />} />
				</Routes>
			</div>
		</BrowserRouter>
		);
	}


export default App;
