import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import Swal from "sweetalert2";
import { getMemberAdsAction,deleteadAction,adBlockUnBlockAction, repostAdsAction, bulkdeleteadAction } from '../Action/action';

const ManageAd = () => {
    if (!Cookies.get('loginSuccessMember')) {
		window.location.href = `${config.baseUrl}`;
	}

    const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));
    const memberId  = loginData?.id;
    const [spinloader, setspinloader] = useState(0);
    const [adsList, setAdsList]  = useState({});  
    const [selectedads, setSelectedads] = useState([]);
	const [selectAll, setSelectAll] = useState(false);  
   
    useEffect(() => {
		setspinloader(1)	
        getMemberAds();
    }, [])
    
  const getMemberAds = async () => {
        try{
            let res = await getMemberAdsAction({id: memberId});            
            setAdsList(res.data);
             setspinloader(0)
        }
        catch (error) {
            console.error(error);
        }
       
    };
    
  const deleteradd = async (adid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Ad!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteadAction({ id: adid });
        if (res.success) {
          getMemberAds();         
          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");          
        }
      }
    });
  };  
  
  const adBlockUnBlock = async (adid, is_active) => {
        Swal.fire({
            title: 'Are you sure?',
            text: is_active == 0 ? "You want to deactivate this ad!”" : "You want to activate this ad!”",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, Deactivate it!' : 'Yes, Activate it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await adBlockUnBlockAction({ 'id': adid, 'status': is_active });
                if (res.success) {
                    getMemberAds();
                    Swal.fire(
                        is_active == 0 ? 'Deactivated!' : 'Activated',
                        res.msg,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Failed!',
                        res.msg,
                        'error'
                    )
                }
            }
        })
    };
	
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedads([]);
    } else {
      setSelectedads(adsList.map(record => record.id));
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (id) => {
    if (selectedads.includes(id)) {
      setSelectedads(selectedads.filter(recordId => recordId !== id));
    } else {
      setSelectedads([...selectedads, id]);
    }
  };	
  
  const rePostSelectedAds = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Repost Selected Ads!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Repost these!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await repostAdsAction(selectedads);
        if (res.success) {
         setSelectedads([]);                 
          Swal.fire("Reposted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");          
        }
      }
    });
  };  
  
  const deleteSelectedAds = async (adid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Selected Ads!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted these!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await bulkdeleteadAction(selectedads);
        if (res.success) {
          getMemberAds(); 
          setSelectedads([]);              
          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");          
        }
      }
    });
  };  
  
  
  
    return (
        <>
            <Header />
                <div className="add-section mb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <Leftnav />
                            </div>
                            <div className="col-lg-6">
                                <Toaster />
                                <div className="classification-section">
								    <h2 className="page-heading"> Manage Ads </h2>
								    
                                    <div className="mt-3">
                                        <div className="table-responsive table-striped ">
                                            <table className="table">
                                                <thead>
                                                    <tr className='lightbluebg'>
                                                        <th>
                                                        {adsList != null && adsList.length > 0 ?
                                                        <input type='checkbox' name='checkAllad' id='checkAllad' checked={selectAll}
                onChange={handleSelectAll} />
                :''}
                </th>
                                                        <th>Ad Title</th>
                                                        <th>Type</th>
                                                        <th>Days Left</th>
                                                        <th>Upgrade</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
														spinloader === 0 ? 
														adsList != null && adsList.length > 0 ?
                                                        adsList.map((row, index) => (
                                                            <tr key={index}>
                                                                <td><input type='checkbox' name='checkad' id={'checkad' + row.id} checked={selectedads.includes(row.id)} onChange={() => handleCheckboxChange(row.id)} /> </td>
                                                                <td>{row.title}</td>
                                                                <td>{row.plan_type == 1 ? 'Featured' : row.plan_type == 2 ? 'Premium' : 'Free' }</td>
                                                                <td>{row.plan_id != 0 ? row.daysLeft+' Days Left' : 'Free' }</td>
                                                                <td><a	href={`${config.baseUrl}upgrade-ad/${row.id}`} className="btn btn-sm btn-primary" id="editbtnid">Upgrade</a></td>
                                                                <td>{row.status == 1 ? 
																	<> 
                                                                 <button type="button" className="btn btn-primary btn-sm " onClick={() => adBlockUnBlock(row.id, 0)}><i className='fa fa-ban'></i> Active</button>
                                                                 </>
                                                                 :
                                                                 <> 
                                                                 <button type="button" className="btn btn-danger btn-sm " onClick={() => adBlockUnBlock(row.id, 1)}><i className='fa fa-unlock'></i> Inactive</button>
                                                                 </>
																	}
                                                                 
                                                                </td>
                                                                <td> <a	href={`${config.baseUrl}edit-ad/${row.id}`} className="btn btn-sm btn-primary" id="editbtnid"><span><i class="fa-solid fa-pen-to-square"></i></span></a>
																	   &nbsp;
																		<button
																		  type="button"
																		  className="btn btn-sm btn-primary"
																		  id="editbtnid"
																		  onClick={() => deleteradd(row.id)}
																		>
																		  <span><i class="fa-solid fa-trash"></i></span>
																		</button>
																		&nbsp;</td>
                                                            </tr>
                                                        ))
                                                    :
                                                        <tr>
                                                            <td colSpan="7">No ad found to display.</td>
                                                        </tr>
                                                      : (
                                                      <tr>
                                                            <td colSpan="7">
															<div className="buy-end-sell">
															  <div className="loaderDiv">
																<img className="loaderImg" src={config.baseUrl + "img/loader.gif"} height={50} width={50} />
															  </div>
															</div>
															</td>
                                                        </tr>
														  )}
												
												{selectedads.length > 1 && (
													 <tr>
                                                            <td colSpan="7" className="text-right">
														<button className="btn btn-sm btn-primary" onClick={() =>rePostSelectedAds()}>Repost</button>&nbsp;&nbsp;
														<button className="btn btn-sm btn-primary" onClick={() => deleteSelectedAds()}>Delete Selected</button>
														</td>
                                                      </tr>
													  )}		  
														  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightnav />
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}

export default ManageAd;

