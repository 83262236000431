import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { getadDataAction, getadImgesAction, getRelatedAdsAction } from '../Action/action';
import moment from 'moment';


const Addetails = () => {
	const { id }						= useParams();
	const { any }						= useParams();
	const [adData, setadData]			= useState({});
	const [spinloader, setspinloader] = useState(0);  
	const [adImges, setadImges]			= useState({});
	const [relatedAds, setRelatedAds]	= useState({});
	const [adUrl, setAdUrl]				= useState('');
	const [catUrl, setCatUrl]			= useState('');
	
	useEffect(() => {		
		getadData();
		getadImges();
		getRelatedAds();
		
	}, [])
	
	const getadData = async () => {
		setspinloader(1)
			let res = await getadDataAction({id:id, any: any});
			if (res.success) {
				setadData(res.data);
				setspinloader(0)
			}
			else{
				window.location.href = `${config.baseUrl}`
			}
		};
		
		const getadImges = async () => {
			let res = await getadImgesAction({id:id});
			if (res.success) {
				setadImges(res.data);
			}else{
				setadImges('');
			}
		};
		
		const getRelatedAds = async () => {
			var currentDate = new Date();
			currentDate	= moment(currentDate).subtract(2, "year");
			currentDate	= moment(currentDate).format('YYYY-MM-DD');
			
			var cat_id	= adData?.category_id;
			
			let res = await getRelatedAdsAction({id:id, catId:cat_id, date:currentDate});
			if (res.success) {
				setRelatedAds(res.data);
			}else{
				setRelatedAds('');
			}
		};
	
	
	
	// function to redirect to ad url on click ad title
	const handleAdUrl = (row) => {
		var pagenewUrl	= '';
		pagenewUrl	= config.baseUrl + 'ad/' + row.id + '/' + row.parent_cat.replace(/ /g,"-").replace(/'/g,"").replace(/&/g,"and").replace(/[^A-Za-z0-9\-\']/g,'').toLowerCase() + '-' + row.cat_name.replace(/ /g,"-").replace(/'/g,"").replace(/&/g,"and").replace(/[^A-Za-z0-9\-\']/g,'').toLowerCase() + '-' + row.title.replace(/ /g,"-").replace(/'/g,"").replace(/&/g,"and").replace(/[^A-Za-z0-9\-\']/g,'').toLowerCase();
		
		window.location.href = `${pagenewUrl}`;
	};
	
	// function to redirect to category ads page on click category title
	const handleCatUrl = (row) => {
		var pagenewUrl	= '';
		pagenewUrl	= config.baseUrl + 'cat/' + row.category_id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();
		
		window.location.href = `${pagenewUrl}`;
	};
	
	// function to create ad url on hover ad title
	const getAdUrl = (row) => {
		var pagenewUrl	= '';
		pagenewUrl	= config.baseUrl + 'ad/' + row.id + '/' + row.parent_cat.replace(/ /g,"-").replace(/'/g,"").replace(/&/g,"and").replace(/[^A-Za-z0-9\-\']/g,'').toLowerCase() + '-' + row.cat_name.replace(/ /g,"-").replace(/'/g,"").replace(/&/g,"and").replace(/[^A-Za-z0-9\-\']/g,'').toLowerCase() + '-' + row.title.replace(/ /g,"-").replace(/'/g,"").replace(/&/g,"and").replace(/[^A-Za-z0-9\-\']/g,'').toLowerCase();
		
		setAdUrl(pagenewUrl);
	};
	
	// function to create category url on hover ad title
	const getCatUrl = (row) => {
		var pagenewUrl	= '';
		pagenewUrl	= config.baseUrl + 'cat/' + row.category_id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();
		
		setCatUrl(pagenewUrl);
	};
	
	return(
		<>
			<Header />
			
			<div className="product-single">
				<div className="container">
					{spinloader==0 ?
					<div className="baner-small">
						<Link className="home-icon" to={config.baseUrl}><i className="fa-solid fa-house-chimney"></i></Link>
						<span>/</span>
						<Link to="#">{adData.parent_cat}</Link>
						<span>/</span>
						<Link to={catUrl} onClick={() => handleCatUrl(adData)} onMouseOver={() => getCatUrl(adData)} onMouseOut={() => setCatUrl('')}>{adData.cat_name} </Link>
						<span>/</span>
						<Link to="#">{adData.title}</Link>
					</div>
					: '' }
					
				</div>
			</div>
			
			<div className="add-section">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<div className="plan-section">
							  {spinloader === 0 ? (
								<>
								  <div className="arrow-btn">
									<div className="back-btn">
									  <Link to="#"><span><i className="fa-solid fa-angles-left"></i></span>Back</Link>
									</div>
									<div className="viewbtn">
									  <div className="prev-btn">
										<Link to="#"><span><i className="fa-solid fa-angles-left"></i></span>Prev</Link>
									  </div>
									  <div className="next-btn">
										<Link to="#">Next<span><i className="fa-solid fa-angles-right"></i></span></Link>
									  </div>
									</div>
								  </div>

								  <div className="heading-super">
									<h6>{adData.title}</h6>
								  </div>
								  <div className="location">
									<p><Link to="#"><span><i className="fa-solid fa-location-dot"></i></span>{adData.suburb} ({adData.area})</Link></p>
								  </div>
								  {parseInt(adData.parent_cat_id)==parseInt(config.cat_per) ? (
								  <>
									 <div class="location">
										<p><span><i class="fa-solid fa-user"></i></span>Age : {adData.age}</p>
									</div>
									 <div class="location">
										<p><span><i class="fa-solid fa-venus-double"></i></span>Gender : {adData.sex==0 ? 'Male': 'Female'}</p>
									</div>
								  </>
								) : parseInt(adData?.parent_cat_id) == parseInt(config.cat_real) ? (
								  <>
									<div className="location">
									  <p><span><i class="fa-regular fa-building"></i></span>Property Type: {adData.property_type}</p>
									</div>
									<div className="location">
									  <p><span><i class="fa-solid fa-street-view"></i></span>Street No : {adData.street_number}</p>
									</div>
									<div className="location">
									  <p><span><i class="fa-solid fa-street-view"></i></span>Street Name : {adData.street_name}</p>
									</div>
									<div className="location">
									<p><span><i className="fa-solid fa-dollar-sign"></i></span> ${adData.sell_price}</p>
								  </div>	
									<div className="location">
									  <p><span><i class="fa-solid fa-bed"></i></span>Bedrooms : {adData.bed_rooms}</p>
									</div>
									<div className="location">
									  <p><span><i class="fa-regular fa-building"></i></span>Unit / Flat : {adData.unit_flat}</p>
									</div>
								  </>
								) : parseInt(adData?.parent_cat_id) == parseInt(config.cat_buy) ? (
								  <>
									 <div className="location">
									<p><span><i className="fa-solid fa-dollar-sign"></i></span> ${adData.sell_price}</p>
								  </div>							
								  </>
								)  : parseInt(adData?.parent_cat_id) == parseInt(config.cat_whats) ? (
								  <>
									<div className="location">
									  <p><span><i class="fa-solid fa-calendar-days"></i></span>Date: {adData.formatted_whatson_date}</p>
									</div>
									<div className="location">
									  <p><span><i class="fa-regular fa-clock"></i></span>Time: {adData.time_whatson}</p>
									</div>
									 <div className="location">
									<p><span><i className="fa-solid fa-dollar-sign"></i></span> ${adData.sell_price}</p>
								  </div>
								  </>
								): parseInt(adData?.parent_cat_id) == parseInt(config.cat_jobs) ? (
								  <>
									<div className="location">
									  <p><span><i class="fa-solid fa-briefcase"></i></span>Work type: {adData.job_work_type}</p>
									</div>									
								  </>
								): parseInt(adData?.parent_cat_id) == parseInt(config.cat_business) ? (
								  <>
									<div className="location">
									  <p><span><i class="fa-solid fa-user-tag"></i></span>ABN: {adData.abn}</p>
									</div>									
									<div className="location">
									  <p><span><i class="fa-regular fa-circle-check"></i></span>Accreditation: {adData.accreditation}</p>
									</div>	
					
								  </>
								)
								: (
								  ''
								)}									  
								  
								 								  
								  <div className="icon-socialmedia user-icon">
									<div className="facebook">
									  <Link to="#"><i className="fa-brands fa-facebook-f"></i></Link>
									</div>
									<div className="facebook twiter">
									  <Link to="#"><i className="fa-solid fa-x"></i></Link>
									</div>
									<div className="facebook linkdin">
									  <Link to="#"><i className="fa-brands fa-linkedin-in"></i></Link>
									</div>
									<div className="facebook piterest">
									  <Link to="#"><i className="fa-brands fa-pinterest-p"></i></Link>
									</div>
									<div className="facebook email">
									  <Link to="#"><i className="fa-solid fa-envelope"></i></Link>
									</div>
									<div className="facebook text-icon">
									  <Link to="#"><i className="fa-solid fa-t"></i></Link>
									</div>
									<div className="facebook text-icon">
									  <Link to="#"><i className="fa-solid fa-heart"></i></Link>
									</div>
									<div className="email-btn">
									  <Link to="#">Email A Friend</Link>
									</div>
									<div className="email-btn">
									 <Link to="#">Report</Link>
									</div>
								  </div>
								  <div className="item-purchased mb-3 mt-3 ad-description" dangerouslySetInnerHTML={{ __html: adData?.discription }}></div>
								  {adImges.length > 0 ? (
								  <><div className="blogs" id="lightgallery">
									  {adImges.map((row, index) => (
										<div key={index} className="gallery-section">
										  <Link to="#">
											<img alt={adData.title} src={config.apiUrl + row.addImg} />
										  </Link>
										</div>
									  ))}
									</div>
								  </>
								) : (
								  ''
								)}	
								 
								  {relatedAds.length > 0 && (
									<>
									  <div className="heading-super">
										<h6>OTHER RELATED ADS</h6>
									  </div>
									  {relatedAds.map((row, index) => (
										<div key={index} className={index % 2 === 0 ? 'section-listing' : 'section-listing section-listing-new'}>
										  <div className="main-item">
											<div className="fast-block">
											  {row.addImg && row.addImg !== '' ? (
												<img src={config.apiUrl + row.addImg} alt={row.title} />
											  ) : (
												<img src={config.baseUrl + "img/noImage.png"} alt={row.title} />
											  )}
											</div>
											<div className="second-block">
											  <Link className="fordhead" to={adUrl} onClick={() => handleAdUrl(row)} onMouseOver={() => getAdUrl(row)} onMouseOut={() => setAdUrl('')}>
												{row.title}
											  </Link>
											  <div className="price">
												<p><strong>${row.sell_price}</strong></p>
											  </div>
											  <p><span><i className="fa-solid fa-location-dot"></i></span> {row.suburb} ({row.area})</p>
											</div>
										  </div>
										</div>
									  ))}
									</>
								  )}
								</>
							  ) : (
								<div className="loaderDiv">
								  <img className="loaderImg" src={config.baseUrl + "img/loader.gif"} height={50} width={50} />
								</div>
							  )}
							</div>

						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Addetails;
