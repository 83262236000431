import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../coreFIles/config'
import { getsidebarMenuAction, getbannerAdsAction } from '../Action/action';
import Cookies from 'js-cookie'

const Leftsidebar = () => {
	const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));
	const [sidebarMenu, setsidebarMenu]	= useState({});
	const [bannerAds, setbannerAds]	= useState({});
	
	useEffect(() => {
		getsidebarMenu();
		getbannerAds();
	}, [])
	
	const getsidebarMenu = async () => {
		let res = await getsidebarMenuAction({keyword:'footer'});
		if (res.success) {
			setsidebarMenu(res.data);
		}
	};
	
	const getbannerAds = async () => {
		let res = await getbannerAdsAction({adColumn:'L'});
		if (res.success) {
			setbannerAds(res.data);
		}
	};
	
	const handleContentUrl = (id) => {
		var pagenewUrl	= '';
		if(id==1)
		{
			pagenewUrl	= config.baseUrl + 'about'
		}
		else if(id==2)
		{
			pagenewUrl	= config.baseUrl + 'advertise-with-us'
		}
		else if(id==3)
		{
			pagenewUrl	= config.baseUrl + 'help'
		}
		else if(id==5)
		{
			pagenewUrl	= config.baseUrl + 'terms-and-conditions'
		}		
		else if(id==6)
		{
			pagenewUrl	= config.baseUrl + 'privacy-policy'
		}
		window.location.href = `${pagenewUrl}`;
	};	
	return (
		<>
			<div className="section-member">
				<div className="btnpost">
				{!loginData || loginData == '' ?
					<Link to={config.baseUrl+'login'}><span><img src={config.baseUrl + "img/ads.png"} alt='ads' /></span>Post Your Ad</Link>
				:
				<Link to={config.baseUrl+'addad'}><span><img src={config.baseUrl + "img/ads.png"} alt='ads' /></span>Post Your Ad</Link>
				}	
				</div>
				{!loginData || loginData == '' ?
					<div className="btnpost">
						<Link to={config.baseUrl + 'login'}><span><i className="fa-solid fa-arrow-right-to-bracket"></i></span>Member Login</Link>
					</div>
				:
					<div className="btnpost">
						<Link to={config.baseUrl + 'manage-ads'}><span><i className="fa-solid fa-arrow-right-to-bracket"></i></span>Manage Ads</Link>
						<Link to=""><span><i className="fa-solid fa-search"></i></span>Search History</Link>
						<Link to=""><span><i className="fa-solid fa-heart"></i></span>Favourite Ads</Link>
					</div>
				}
				

				<div className="domorecor-logo">
					<img src={config.baseUrl + "img/domorecor.png"} alt='do more' />
				</div>
				<div className="fbbtn">
					<Link to=""><span><i className="fa-brands fa-facebook-f"></i></span>Login With Facebook</Link>
				</div>
				<div className="about-section">
					<ul>
						<h4>MENU</h4>
						{sidebarMenu.length > 0 ?
							sidebarMenu.map((row) => (
								<li><Link to="javascript:;" onClick={() => handleContentUrl(row.content_id)}>{row.title}</Link></li>
							))
						: ''}
						{!loginData || loginData == '' ?
							(
							<li><Link to={config.baseUrl + 'vipmembership'}>VIP MEMBERSHIP</Link></li>
							)
							:
							(
								<li><Link to={config.baseUrl + 'upgrade-account'}>VIP MEMBERSHIP</Link></li>
							)
						}
							
						
						
						<li><Link to="">TELL A FRIEND</Link></li>
						<li><Link to={config.baseUrl + 'contact-us'}>CONTACT US</Link></li>
						
						{/* <li><Link to="">ABOUT US</Link></li>
						<li><Link to="">ADVERTISE WITH US</Link></li>
						<li><Link to="">VIP MEMBERSHIP</Link></li>
						<li><Link to="">TELL A FRIEND</Link></li>
						<li><Link to="">HELP</Link></li>
						<li><Link to="">CONTACT US</Link></li> */}
					</ul>
				</div>
				<div className="towblock">
					{bannerAds.length > 0 ? (
						bannerAds.map((row) => (
							<div className="heroimg">
								{row.ad_type == 'img' ?
									<Link to={row.ad_url} target='_blank'>
										<img src={config.bannersUrl + row.ad_file} alt={row.ad_title} />
									</Link>
								: ''}
							</div>
						))
					) : ''}
				</div>
			</div>
		</>
	)
}

export default Leftsidebar;
