import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { ContactusPostAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));

const Profile = () => {
	
	
	const [spinloader, setspinloader] 					= useState(0); 	
	const [validationError, setvalidationError] 		= useState({});
	const recaptchaRef = React.createRef();		
	const [form, setForm] = useState({	
		name	: '',	
		email	: '',
		phone	: '',
		msg	: '',
		captchaValue:''
	});	
	useEffect(() => {		
		
	}, [])	
	
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	
	const inputPhoneHandler = async (e) => {
		const { name, value } = e.target
		if (/^\d{0,10}$/.test(value)) {
		  setForm((old) => {
				return { ...old, [name]: value }
			})
		}		
	}
	const handleKeyDown = (e) => { 
		if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
		  e.preventDefault();
		}
	  };
    
	function validate() {
		let nameError = "";
		let emailError = "";	
		let phoneError = "";	
		let msgError = "";		
		let captchaValueError = "";		
		if (form.name === '') {
			nameError = "Name is required."
		}		
		if (form.email === '') {
			emailError = "Email is required."
		}
		if (form.phone === '') {
			phoneError = "Phone is required."
		}
		if (form.msg === '') {
			msgError = "Message is required."
		}					
		if (form.captchaValue === '') {
			captchaValueError = "Captcha is required."
		}					
		if (nameError || emailError || phoneError || msgError || captchaValueError) {
			setvalidationError({
				nameError,emailError,phoneError,msgError,captchaValueError
			})			
			return false
		} else {
			setvalidationError({
				nameError,emailError,phoneError,msgError,captchaValueError
			})
			return true
		}
	}
	
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {
	
		}
		else {
			setspinloader(1);   
			let res = await ContactusPostAction(form);
			if (res.success) {
				toast.success(res.msg);						
			}			 
			else {
				toast.error(res.msg);
				setspinloader(0);
			}
		}
	}
	
	return(
		<>
			<Header />
			
			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<Toaster />
							
							<div className="classification-section">
								<h2 className="page-heading"> Contact Us </h2>
								<form action='' method='post'>
									<div className="bondiclassified-fromsection">						
										<div className="catagoryfrom">	
											<div className="row" >
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="name">Name <span className='text-danger'>*</span></label>
														<input type='text' className='form-control' name='name' id='name' onChange={inputHandler} value={form.name} />
														<span className="text-danger">{validationError.nameError}</span>
													</div>
												</div>												
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="email">Email <span className='text-danger'>*</span></label>
														<input type='text' className='form-control' name='email' id='email' onChange={inputHandler}  value={form.email} />
														<span className="text-danger">{validationError.emailError}</span>
													</div>
												</div>												
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="phone">Phone <span className='text-danger'>*</span></label>
														<input type='text' className='form-control' name='phone' id='phone' onChange={inputHandler}  value={form.phone} />
														<span className="text-danger">{validationError.phoneError}</span>
													</div>
												</div>	
																							
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="msg">Message <span className='text-danger'>*</span></label>
														<textarea className="form-control" name="msg" rows='5' id='msg' onChange={inputHandler} value={form.msg}></textarea>
														<span className="text-danger">{validationError.msgError}</span>
													</div>
												</div>												
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<ReCAPTCHA
															  ref={recaptchaRef}
															  sitekey="YOUR_RECAPTCHA_SITE_KEY"
															  onChange={inputHandler}
															/>
															<span className="text-danger">{validationError.captchaValueError}</span>
													</div>
												</div>												
												<div className="col-md-12 mt-3 pdb-20">
													  {spinloader == '0' ? (
														<>												 
														  <button
															type="button"
															onClick={SubmitForm}
															className="btn btn-primary login-btn"
														  >
															Send
														  </button>
														</>
													  ) : (
														<button disabled className="btn btn-primary login-btn">
														  Sending.. <i className="fa fa-spinner fa-spin validat"></i>
														</button>
													  )}
													</div>
																								
												
											</div>
										</div>										
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>
			
			<Footer />
		</>
	)
	
}

export default Profile;
