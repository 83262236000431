import {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    postRequestFormData,
    postRequestadFormData,
} from "../coreFIles/helper";


export const LoginAction = (data) => {
    return postRequest("memberLogin", data).then((res) => {
        return res.data;
    });
};

export const getMemberProfilesAction = (data) => {
  return postRequest('getMemberProfile', data).then(res => { return res.data })
}

export const getMemberDetailsAction = (data) => {
  return postRequest('getMemberDetails', data).then(res => { return res.data })
}

export const updateMemberProfileAction = (data) => {
  return postRequest('updateMemberProfile', data).then(res => { return res.data })
}

export const updateMemberpasswordAction = (data) => {
  return postRequest('updateMemberpassword', data).then(res => { return res.data })
}


export const getCategoriesAction = (data) => {
    return getRequest("getCategories", data).then((res) => {
        return res.data;
    });
};

export const getFooterCategoriesAction = (data) => {
    return getRequest("getFooterCategories", data).then((res) => {
        return res.data;
    });
};

export const latestAdsAction = (data) => {
    return getRequest("latestAds", data).then((res) => {
        return res.data;
    });
};

export const featuredAdsAction = (data) => {
    return getRequest("featuredAds", data).then((res) => {
        return res.data;
    });
};

export const homeSlidersAction = (data) => {
    return getRequest("homeSliders", data).then((res) => {
        return res.data;
    });
};

export const homeHozAdsAction = (data) => {
    return getRequest("homeHozAds", data).then((res) => {
        return res.data;
    });
};

export const getnavChildCategoriesAction = (data) => {
    return postRequest("getnavChildCategories", data).then((res) => {
        return res.data;
    });
};

export const getStatesAction = (data) => {
    return getRequest("getAllStates", data).then((res) => {
        return res.data;
    });
};

export const getSearchSuburbAction = (data) => {
    return postRequest("getSrchSuburb", data).then((res) => {
        return res.data;
    });
};

export const getSearchAdtitleAction = (data) => {
    return postRequest("getSearchAd", data).then((res) => {
        return res.data;
    });
};

export const getCategoryChildsAction = (data) => {
    return postRequest("getCategoryChilds", data).then((res) => {
        return res.data;
    });
};

export const getAllCatChildsAction = (data) => {
    return getRequest("getAllCatChilds", data).then((res) => {
        return res.data;
    });
};

export const getChildCatsCountAction = (data) => {
    return getRequest("getChildCatsCount", data).then((res) => {
        return res.data;
    });
};

export const getContentByKeywordAction = (data) => {
    return postRequest("getContentByKeyword", data).then((res) => {
        return res.data;
    });
};

export const getfooterMenuAction = (data) => {
    return getRequest("getfooterMenu", data).then((res) => {
        return res.data;
    });
};

export const getsidebarMenuAction = (data) => {
    return getRequest("getsidebarMenu", data).then((res) => {
        return res.data;
    });
};

export const getbannerAdsAction = (data) => {
    return getRequest("getbannerAds", data).then((res) => {
        return res.data;
    });
};

export const getadDataAction = (data) => {
    return postRequest("getadData", data).then((res) => {
        return res.data;
    });
};

export const getadImgesAction = (data) => {
    return postRequest("getadImges", data).then((res) => {
        return res.data;
    });
};

export const deladimgAction = (data) => {
    return postRequest("deladimg", data).then((res) => {
        return res.data;
    });
};

export const getRelatedAdsAction = (data) => {
    return postRequest("getRelatedAds", data).then((res) => {
        return res.data;
    });
};

export const catDetailsAction = (data) => {
    return postRequest("catDetails", data).then((res) => {
        return res.data;
    });
};

export const categoryPreadsAction = (data) => {
    return postRequest("categoryPreads", data).then((res) => {
        return res.data;
    });
};

export const categoryFreeadsAction = (data) => {
    return postRequest("categoryFreeads", data).then((res) => {
        return res.data;
    });
};

export const premiumAdsCountAction = (data) => {
    return postRequest("premiumAdsCount", data).then((res) => {
        return res.data;
    });
};

export const freeAdsCountAction = (data) => {
    return postRequest("freeAdsCount", data).then((res) => {
        return res.data;
    });
};

export const parentCatDetailsAction = (data) => {
    return postRequest("parentCatDetails", data).then((res) => {
        return res.data;
    });
};

export const categoryAllPreadsAction = (data) => {
    return postRequest("categoryAllPreads", data).then((res) => {
        return res.data;
    });
};

export const categoryAllFreeadsAction = (data) => {
    return postRequest("categoryAllFreeads", data).then((res) => {
        return res.data;
    });
};

export const premiumAdsAllCountAction = (data) => {
    return postRequest("premiumAdsAllCount", data).then((res) => {
        return res.data;
    });
};

export const freeAdsAllCountAction = (data) => {
    return postRequest("freeAdsAllCount", data).then((res) => {
        return res.data;
    });
};

export const getCountriesAction = (data) => {
    return postRequest("getCountries", data).then((res) => {
        return res.data;
    });
};

export const createMemberAction = (data) => {
    return postRequest("createMember", data).then((res) => {
        return res.data;
    });
};

export const getPlansAction = (data) => {
    return getRequest("getPlans", data).then((res) => {
        return res.data;
    });
};

export const getPlanDetailsAction = (data) => {
    return postRequest("getPlanDetails", data).then((res) => {
        return res.data;
    });
};

export const createAdAction = (data) => {
    return postRequestadFormData("createNewAd", data).then((res) => {
        return res.data;
    });
};

export const updateAdAction = (data) => {
    return postRequestadFormData("updateuserAd", data).then((res) => {
        return res.data;
    });
};
export const getAdDetailAction = (data) => {
    return postRequest("getuserAdDetail", data).then((res) => {
        return res.data;
    });
};

export const getAdPaymentHistoryAction = (data) => {
    return postRequest("getUserAdPaymentHistory", data).then((res) => {
        return res.data;
    });
};

export const getChildCatsAction = (data) => {
    return postRequest("getChildCategories", data).then((res) => {
        return res.data;
    });
};

export const getMemberAdsAction = (data) => {
    return postRequest("getMemberAds", data).then((res) => {
        return res.data;
    });
};

export const deleteadAction = (data) => {
    return postRequest("deletead", data).then((res) => {
        return res.data;
    });
};
export const repostAdsAction = (data) => {
    return postRequest("repostAds", data).then((res) => {
        return res.data;
    });
};
export const bulkdeleteadAction = (data) => {
    return postRequest("bulkdeletead", data).then((res) => {
        return res.data;
    });
};
export const adBlockUnBlockAction = (data) => {
    return postRequest("useradBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const ContactusPostAction = (data) => {
    return postRequest("ContactusPost", data).then((res) => {
        return res.data;
    });
};
export const getContentAction = (data) => {
    return postRequest("getContent", data).then((res) => {
        return res.data;
    });
};
